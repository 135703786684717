/* @flow */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import request from '../../services/request';
import {
  toggleSpinner, updateDialogStates, togglePaginationSpinner
} from '../dialogStates/dialogStatesSlice';
import { initialAirFreightReportFieldState, airFreightReportFieldList } from '../../constants/staticData';
import {
  checkForColumnData,
  mapInputFieldsToRequest, mapRequestToInputFields, setValidFields
} from '../../helpers/dataHelpers';
import airFreightReportHeader, {
  airFreightReportHiddenHeader,
  airFreightReportPdfExportHeaderFileds
} from '../../constants/airFreightReportHeader';
import getflattenResponse, { cryptoRand } from '../../helpers/common';
import appConfig from '../../appConfig';
import { showForAF, showForPROD, showForTypePayload } from '../../helpers/envServer';
import {
  getFieldsList, setDivisionCode20, searchNewFieldOnSavedSearch
} from './airFreightHelper';
import serviceAPI from '../../helpers/serviceAPI';
import airFreightMock from '../mock/airFreightMock';
import airFreightSubtableMock from '../mock/airFreightSubtableMock';
import airFreightReportFieldMapping from '../../constants/fieldMappings/airFreightReportFieldMapping';
import airFreightReportFieldProperties from '../../constants/fieldProperties/airFreightReportFieldProperties';

export const name = 'airFreightReport';

function createInitialState() {
  return {
    isSavedSearchModified: false,
    airFreightReportRequestBody: null,
    airFreightReportInputFieldData: { ...initialAirFreightReportFieldState },
    airFreightReportSelectedFields: [],
    airFreightReportBookmarkName: '',
    airFreightReportBookmarkId: '',
    requestError: false,
    airFreightSubtableRequestBody: null,

    airFreightReportResultData: {},
    airFreightReportSearchId: '',
    airFreightReportTotalCount: 0,
    airFreightReportNextResults: {},
    airFreightReportPrevResults: {},
    page: 0,
    rowsPerPage: 100,
    airFreightSubtableResults: {},
    airFreightHistorySubtableResults: {}
  };
}

export const initialState = createInitialState();

function createReducers() {
  function updateAirFreightReportPage(state: Object, action: Object) {
    // UPDATE_AIR_FREIGHT_REPORT_PAGE
    return { ...state, page: action.payload };
  }

  function updateAirFreightReportRowsPerPage(state: Object, action: Object) {
    // UPDATE_AIR_FREIGHT_REPORT_ROWS_PER_PAGE
    return { ...state, rowsPerPage: action.payload };
  }

  function updateAirFreightReportRequestBody(state: Object, action: Object) {
    const requestExist = JSON.stringify(state.airFreightReportRequestBody);
    // UPDATE_AIR_FREIGHT_REPORT_REQUEST_BODY
    return {
      ...state,
      airFreightReportRequestBody: action.payload,
      isSavedSearchModified: Boolean(
        state.airFreightReportBookmarkId && requestExist !== JSON.stringify(action.payload)
      )
    };
  }

  function updateAirFreightSubtableRequestBody(state: Object, action: Object) {
    // UPDATE_AIR_FREIGHT_SUBTABLE_REQUEST_BODY
    return {
      ...state,
      airFreightSubtableRequestBody: action.payload
    };
  }

  function updateIsSavedSearchModified(state: Object, action: Object) {
    return {
      ...state,
      isSavedSearchModified: Boolean(action.payload)
    };
  }

  function updateAirFreightReportRequestBodyOnSameBookmark(state: Object, action: Object) {
    // UPDATE_AIR_FREIGHT_SEARCH_REQUEST_ON_SAME_BOOKMARK
    return {
      ...state,
      airFreightReportRequestBody: action.payload
    };
  }

  function resetAirFreightReportResults(state: Object) {
    // RESET_AIR_FREIGHT_REPORT_RESULTS
    // return {
    //   ...state,
    //   airFreightReportRequestBody: action.payload
    // };
    return {
      ...state,
      airFreightReportResultData: {},
      airFreightReportSearchId: '',
      airFreightReportTotalCount: 0,
      airFreightReportNextResults: {},
      airFreightReportPrevResults: {},
      page: 0,
      rowsPerPage: 100,
      airFreightReportSearchPanelId: ''
    };
  }

  function resetAirFreightReportRequest(state: Object) {
    // RESET_AIR_FREIGHT_REPORT_REQUEST
    return {
      ...initialState,
      countryList: state.countryList,
      plantCodeList: state.plantCodeList
    };
  }

  function resetAirFreightRequestForClearSearch(state: Object) {
    // RESET_AIR_FREIGHT_REQUEST_DATA_CLEAR_SEARCH
    return {
      ...state,
      isSavedSearchModified: false,
      airFreightReportRequestBody: null,
      airFreightReportInputFieldData: { ...initialAirFreightReportFieldState },
      airFreightReportSelectedFields: [...new Set(state.airFreightReportSelectedFields)],
      airFreightReportBookmarkName: '',
      airFreightReportBookmarkId: '',
      requestError: false
    };
  }

  function updateAirFreightReportFields(state: Object, action: Object) {
    // UPDATE_AIR_FREIGHT_REPORT_SELECTED_FIELDS
    return {
      ...state, airFreightReportSelectedFields: action.payload
    };
  }

  function updateAirFreightReportInputValues(state: Object, action: Object) {
    // UPDATE_AIR_FREIGHT_REPORT_INPUT_VALUES
    return { ...state, airFreightReportInputFieldData: action.payload };
  }

  function updateAirFreightReportBookmarkId(state: Object, action: Object) {
    // UPDATE_AIR_FREIGHT_REPORT_BOOKMARK_ID
    return {
      ...state,
      isSavedSearchModified: false,
      airFreightReportBookmarkId: action.payload
    };
  }

  function clearSavedAirFreightReport(state: Object) {
    // CLEAR_AIR_FREIGHT_REPORT_BOOKMARK
    return {
      ...state,
      isSavedSearchModified: false,
      airFreightReportBookmarkId: '',
      airFreightReportBookmarkName: ''
    };
  }

  function updateairFreightReportBookmarkName(state: Object, action: Object) {
    // AIR_FREIGHT_REPORT_BOOKMARK_NAME
    return {
      ...state,
      isSavedSearchModified: false,
      airFreightReportBookmarkName: action.payload
    };
  }

  function updateAirFreightReportResults(state: Object, action: Object) {
    // UPDATE_AIR_FREIGHT_REPORT_RESULTS
    const payload = {
      data: getflattenResponse(action.payload.data),
      dataNext: null
    };
    return {
      ...state,
      airFreightReportResultData: payload.data,
      airFreightReportTotalCount: payload.data.pages.totalResources,
      airFreightReportSearchId: cryptoRand().toString().substr(2, 8),
      selectedRecordsCount: 0,
      airFreightReportNextResults: null,
      airFreightReportPrevResults: null
    };
  }

  function updateAirFreightSubtableResults(state: Object, action: Object) {
    // UPDATE_AIR_FREIGHT_SUBTABLE_RESULTS
    const payload = {
      data: getflattenResponse(action.payload),
      dataNext: null
    };
    return {
      ...state,
      airFreightSubtableResults: payload.data
    };
  }

  function updateAirFreightHistorySubtableResults(state: Object, action: Object) {
    // UPDATE_AIR_FREIGHT_HISTORY_SUBTABLE_RESULTS
    const payload = {
      data: getflattenResponse(action.payload),
      dataNext: null
    };
    return {
      ...state,
      airFreightHistorySubtableResults: payload.data
    };
  }

  function updateAirFreightReportSearchId(state: Object) {
    // UPDATE_AIR_FREIGHT_REPORT_SEARCH_ID
    return { ...state, airFreightReportSearchId: cryptoRand().toString().substr(2, 8) };
  }

  function resetAirFreightReportInputData(state: Object) {
    // RESET_AIR_FREIGHT_REPORT_INPUT_VALUES
    return {
      ...state,
      airFreightReportInputFieldData: initialState.airFreightReportInputFieldData
    };
  }

  function updateAirFreightReportNextResult(state: Object, action: Object) {
    // UPDATE_AIR_FREIGHT_REPORT_NEXT_RESULTS
    const payload = {
      data: getflattenResponse(action.payload.currentResultSet),
      dataNext: getflattenResponse(action.payload.response.data)
    };
    return {
      ...state,
      airFreightReportResultData: payload.data,
      airFreightReportNextResults: payload.dataNext,
      airFreightReportTotalPages: payload.data.pages.totalPages,
      airFreightReportTotalCount: payload.data.pages.totalResources
    };
  }

  function updateAirFreightReportPrevResult(state: Object, action: Object) {
    // UPDATE_AIR_FREIGHT_REPORT_PREV_RESULTS
    const payload = {
      data: getflattenResponse(action.payload.currentResultSet),
      dataPrev: getflattenResponse(action.payload.response.data)
    };
    return {
      ...state,
      airFreightReportResultData: payload.data,
      airFreightReportPrevResults: payload.dataPrev,
      airFreightReportTotalPages: payload.data.pages.totalPages,
      airFreightReportTotalCount: payload.data.pages.totalResources
    };
  }

  function airFreightReportOffset(state: Object, action: Object) {
    // AIR_FREIGHT_REPORT_RESULT_OFFSET
    const payload = {
      data: getflattenResponse(action?.payload?.data)
    };

    return {
      ...state,
      airFreightReportResultData: payload.data,
      airFreightReportTotalCount: payload.data.pages.totalResources,
      airFreightReportSearchId: cryptoRand().toString().substr(2, 8),
      selectedRecordsCount: 0,
      airFreightReportNextResults: null,
      airFreightReportPrevResults: null
    };
  }

  function ChangeReportAppendNextResultSet(state: Object) {
    // UPDATE_AIR_FREIGHT_REPORT_NEXT_APPEND
    const updatedCurrentResultSet = state.airFreightReportResultData;
    const updatedNextResultSet = state.airFreightReportNextResults;

    const payload = {
      dataPrev: updatedCurrentResultSet,
      data: updatedNextResultSet
    };

    return {
      ...state,
      airFreightReportResultData: payload.data,
      airFreightReportPrevResults: payload.dataPrev,
      airFreightReportNextResults: null
    };
  }

  // function PoSearchResultsPrev(state: Object, action: Object) {
  //   // PO_SEARCH_RESULT_PREV_SUCCESS

  //   const payload = {
  //     data: action.payload.currentResultSet,
  //     dataPrev: getflattenResponse(action.payload.response.data)
  //   };

  //   return {
  //     ...state,
  //     airFreightReportResultData: payload.data,
  //     airFreightReportPrevResults: payload.dataPrev,
  //     airFreightReportNextResults: null
  //   };
  // }

  function ChangeReportAppendPrevResultSet(state: Object) {
    // UPDATE_AIR_FREIGHT_REPORT_PREV_APPEND
    const updatedPrevResultSet = state.airFreightReportPrevResults;
    const updatedCurrentResultSet = state.airFreightReportResultData;

    const payload = {
      data: updatedPrevResultSet,
      dataNext: updatedCurrentResultSet
    };

    return {
      ...state,
      airFreightReportResultData: payload.data,
      airFreightReportNextResults: payload.dataNext,
      airFreightReportPrevResults: null
    };
  }

  function setCountryCodeDropdown(state: Object, action: Object) {
    // SET_COUNTRY_CODE_DROPDOWN_VALUES

    return {
      ...state,
      countryList: action.payload
    };
  }

  function setPlantCodeDropdown(state: Object, action: Object) {
    return {
      ...state,
      plantCodeList: action.payload
    };
  }

  return {
    updateAirFreightReportPage,
    updateAirFreightReportRowsPerPage,
    updateAirFreightReportRequestBody,
    updateIsSavedSearchModified,
    updateAirFreightSubtableRequestBody,
    updateAirFreightReportRequestBodyOnSameBookmark,
    resetAirFreightReportResults,
    resetAirFreightReportRequest,
    resetAirFreightRequestForClearSearch,
    updateAirFreightReportFields,
    updateAirFreightReportInputValues,
    updateAirFreightReportBookmarkId,
    clearSavedAirFreightReport,
    updateairFreightReportBookmarkName,
    updateAirFreightReportResults,
    updateAirFreightSubtableResults,
    updateAirFreightHistorySubtableResults,
    updateAirFreightReportSearchId,
    resetAirFreightReportInputData,
    updateAirFreightReportNextResult,
    updateAirFreightReportPrevResult,
    airFreightReportOffset,
    ChangeReportAppendNextResultSet,
    ChangeReportAppendPrevResultSet,
    setCountryCodeDropdown,
    setPlantCodeDropdown
  };
}

export const reducers = createReducers();
export const slice = createSlice({ name, initialState, reducers });

const actions = { ...slice.actions };

function createExtraActions() {
  // ppmReportResults -- start
  function sendAttachedFile() {
    return createAsyncThunk(
      `${name}/sendAttachedFile`,
      async ({
        fileData, callback
      }, { dispatch, getState }) => {
        dispatch(toggleSpinner(true));
        request({
          api: 'uploadFileForOverride',
          method: 'fileUpload',
          data: fileData
        }, dispatch, getState).then((response) => {
          dispatch(toggleSpinner(false));
          if (callback) {
            callback(response.data);
          }
        }).catch((error) => {
          dispatch(toggleSpinner(false));
          if (callback) {
            callback(null, error);
          }
        });
      }
    );
  }

  function fetchAirFreightReports() {
    return createAsyncThunk(
      `${name}/fetchAirFreightReports`,
      async ({
        data, callback, modifiedrequestBody, overrideEligibleOnly, inactiveFeatureFields
      }, { getState, dispatch }) => {
        dispatch(toggleSpinner(true));
        const secondaryFields = [];
        getFieldsList(secondaryFields);
        const state = getState();
        let requestData = {};
        const {
          selectedColumnOrderOptions,
          rowPerPageOption: rowsPerPageOption
        } = state.searchpaneldata || {};

        let columnOrderOptions = [...selectedColumnOrderOptions];
        if (checkForColumnData(state, true)) {
          airFreightReportHeader.forEach((headRow: Object) => {
            if (headRow.includeInRequest) {
              columnOrderOptions = [...columnOrderOptions, headRow.primary];
            }
          });
        }
        if (checkForColumnData(state, true)) {
          airFreightReportHiddenHeader.forEach((headerRow: Object) => {
            if (headerRow.includeInRequest && !columnOrderOptions.includes('dpomAirFreight.isLocked')) {
              columnOrderOptions.push(headerRow.primary);
            }
          });
        }
        const fieldsForRequest = checkForColumnData(state, true)
          ? columnOrderOptions
          : secondaryFields;
        if (data) {
          requestData = mapInputFieldsToRequest(data, 'airFreightReport');
          const appliedAirFreightSearchField = [
            ...new Set(requestData.map((field) => field.fieldName))
          ];
          dispatch(actions.updateAirFreightReportInputValues(data));
          dispatch(actions.updateAirFreightReportFields(airFreightReportFieldList
            .map((field) => field.key)
            .filter((field) => appliedAirFreightSearchField.includes(field))));
        } else {
          requestData = state.airFreightReport.airFreightReportRequestBody.search;
        }
        if (Array.isArray(requestData)
          && !requestData.map((field) => field.fieldName === 'poLine.transportationModeCode').some((value) => value === true)
        ) {
          const createDate = showForPROD
            ? appConfig.airFreightPRODDate
            : appConfig.airFreightNONPRODDate;
          requestData.push(
            {
              fieldName: 'poLine.transportationModeCode',
              operator: '=',
              fieldValue: ['AF']
            },
            {
              fieldName: 'poHeader.poCreatedOn',
              operator: '>=',
              fieldValue: createDate
            },
            {
              fieldName: 'dpomAirFreight',
              function: 'IS_DEFINED'
            }
          );
        }

        // The following check is used when we search for a new field on a saved search
        requestData = searchNewFieldOnSavedSearch(requestData);

        // when overrideEligible is checked
        if (overrideEligibleOnly) {
          requestData.push(
            {
              fieldName: 'dpomAirFreight.isLocked',
              function: 'NOT IS_DEFINED'
            }
          );
        }
        const requestBody = (state.airFreightReport.airFreightReportRequestBody)
          ? {
            ...state.airFreightReport.airFreightReportRequestBody,
            search: requestData,
            offset: '0',
            ...modifiedrequestBody
          } : {
            fields: fieldsForRequest,
            search: requestData,
            count: appConfig.SearchThresholdLimit,
            offset: '0',
            ...modifiedrequestBody
          };
        const newSearch = [];
        const newSearchWithoutIsLocked = [...requestBody.search];

        const isLocked = requestBody.search
          .map((field) => field.fieldName === 'dpomAirFreight.isLocked')
          .some((value) => value === true);

        if (isLocked) {
          requestBody.search.forEach((field) => {
            const fieldObj = { ...field };
            // 27046: when date(2024-12-12) the to date should be 2025-01-01 not 2024-12-13
            // to fix this issue on overrirde eligible commented below code
            // if (field.fieldName === 'poLine.goodsAtConsolidatorDate') {
            //   const newDt = new Date(field.fieldValue);
            //   newDt.setHours(newDt.getHours() - 12);
            //   const utcDt = new Date(newDt);
            //   fieldObj.fieldValue = `${utcDt.getUTCFullYear()}-${String(
            //     utcDt.getUTCMonth() + 1
            //   ).padStart(2, '0')}-${String(utcDt.getUTCDate() + 1).padStart(2, '0')}`;
            // }
            newSearch.push(fieldObj);
          });
        }
        let payload = { ...requestBody };
        payload = {
          ...requestBody,
          fields: [
            ...requestBody.fields,
            ...appConfig.includeIsLockedInAF.filter(
              (item) => !requestBody.fields.includes(item)
            )
          ],
          search: isLocked ? [...newSearch] : newSearchWithoutIsLocked
        };
        if (payload?.search) {
          // to add division: 20 in request data
          payload = {
            ...payload,
            search: setDivisionCode20(payload.search)
          };
        }
        payload = setValidFields(
          airFreightReportFieldProperties,
          payload,
          [],
          inactiveFeatureFields
        );

        if (serviceAPI('airFreightReportSearch').mock) {
          dispatch(actions.updateAirFreightReportRequestBody(requestBody));
          dispatch(toggleSpinner(false));
          callback(airFreightMock);
          dispatch(actions.updateAirFreightReportResults({ data: airFreightMock }));
        } else {
          request({
            api: 'airFreightReportSearch',
            method: 'post',
            data: showForTypePayload ? { ...payload, type: 'airFreight' } : payload,
            cancellable: true
          }, dispatch, getState)
            .then((response) => {
              dispatch(actions.updateAirFreightReportRequestBody(requestBody));
              dispatch(toggleSpinner(false));
              callback(response.data);
              dispatch(actions.updateAirFreightReportRowsPerPage(rowsPerPageOption));
              dispatch(actions.updateAirFreightReportResults(response));
            })
            .catch((error) => {
              dispatch(toggleSpinner(false));
              callback(null, error);
            });
        }
      }
    );
  }

  function deleteOverrideAttachment() {
    return createAsyncThunk(
      `${name}/deleteOverrideAttachment`,
      async ({
        fileLocation, callback
      }, { dispatch, getState }) => {
        dispatch(toggleSpinner(true));
        request({
          api: 'deleteOverrideAttachment',
          method: 'delete',
          routeParams: { fileLocation }
        }, dispatch, getState).then((response) => {
          dispatch(toggleSpinner(false));
          if (callback) {
            callback(response);
          }
        }).catch((err) => {
          dispatch(toggleSpinner(false));
          if (callback) {
            callback(null, err);
          }
        });
      }
    );
  }

  function getOverrideAttachmentFile() {
    return createAsyncThunk(
      `${name}/getOverrideAttachmentFile`,
      async ({
        location
      }, dispatch, getState) => {
        request({
          api: 'getOverrideAttachmentFile',
          routeParams: { location: location || '' },
          method: 'get'
        }, dispatch, getState).then((response) => {
          window.open(response.data.attachmentSasUrl);
        }).catch((error) => {
          console.log('error', error);
        });
      }
    );
  }

  function fetchAirFreightHistorySubTable() {
    return createAsyncThunk(
      `${name}/fetchAirFreightHistorySubTable`,
      async ({
        rowId, callback
      }, { dispatch, getState }) => {
        dispatch(toggleSpinner(true));
        const query = rowId ? `poId=${rowId}` : '';
        request({
          api: 'airFreightApprovalHistoryTable',
          method: 'get',
          routeParams: { query },
          cancellable: true
        }, dispatch, getState)
          .then((response) => {
            callback(response.data);
            dispatch(actions.updateAirFreightHistorySubtableResults(response.data));
            dispatch(toggleSpinner(false));
          })
          .catch((error) => {
            dispatch(toggleSpinner(false));
            callback(null, error);
          });
      }
    );
  }

  function fetchAirFreightSubTable() {
    return createAsyncThunk(
      `${name}/fetchAirFreightSubTable`,
      async ({
        rowId, callback
      }, { getState, dispatch }) => {
        dispatch(toggleSpinner(true));
        const fields = [
          airFreightReportFieldMapping.AIR_FREIGHT_PO_NUMBER,
          airFreightReportFieldMapping.AIR_FREIGHT_ITEM_NUMBER,
          'dpomGacHistoryData'
        ];
        const state = getState();
        const requestData = state.airFreightReport.airFreightReportRequestBody.search;
        const requestBody = {
          search: requestData,
          offset: '0',
          fields: [...fields],
          count: appConfig.SearchThresholdLimit
        };
        let payload = { ...requestBody };
        if (payload?.search) {
          // to add division: 20 in request data
          payload = {
            ...payload,
            search: setDivisionCode20(payload?.search)
          };
        }
        if (serviceAPI('airFreightSubtable').mock) {
          dispatch(toggleSpinner(false));
          callback(airFreightSubtableMock);
          dispatch(actions.updateAirFreightSubtableResults({ data: airFreightSubtableMock }));
        } else {
          request({
            api: 'airFreightReportSearch',
            method: 'post',
            data: showForTypePayload ? { ...payload, type: 'airFreight' } : payload,
            cancellable: true
          }, dispatch, getState)
            .then((response) => {
              dispatch(actions.updateAirFreightSubtableRequestBody(requestBody));
              dispatch(toggleSpinner(false));
              const filteredData = response.data.objects.filter((x) => x.id === rowId);
              callback(filteredData);
              dispatch(actions.updateAirFreightSubtableResults(filteredData));
            })
            .catch((error) => {
              dispatch(toggleSpinner(false));
              callback(null, error);
            });
        }
      }
    );
  }
  function removeDuplicates(fields) {
    return Array.from(new Set(fields));
  }
  function exportAirFreightReport() {
    return createAsyncThunk(
      `${name}/exportAirFreightReport`,
      async ({
        callback, filetype, inactiveFeatureFields
      }, { getState, dispatch }) => {
        const state = getState();
        dispatch(toggleSpinner(true));
        const searchRequestBody = {
          ...state.airFreightReport.airFreightReportRequestBody
        };
        const tempRequestData = JSON.parse(JSON.stringify(searchRequestBody));
        if (Array.isArray(searchRequestBody.fields)
          && (searchRequestBody.fields.includes('dpomAirFreight.faf')
            || searchRequestBody.fields.includes('dpomAirFreight.naf'))) {
          tempRequestData.fields.push(...appConfig.includeFieldsAirFreight);
        }
        const removeAdditionalFields = [
          ...airFreightReportHeader.filter((column) => column.additionalFields)
            .map((headRow) => headRow.additionalFields[0])
        ];
        const filedsIncludingDuplicate = tempRequestData.fields.filter(
          (item) => !removeAdditionalFields.includes(item)
        );
        const uniqueFields = removeDuplicates(filedsIncludingDuplicate);
        const duplicateKeysAre = uniqueFields.filter(
          (keys, index) => uniqueFields.indexOf(keys) !== index
        );
        const fieldToExcludes = [
          ...appConfig.excludedPOFieldsInExportedFile,
          ...airFreightReportHeader.filter((column) => column.includeInRequest)
            .map((headRow) => headRow.primary)
        ];
        let distinctAppConfigKeys = [];
        if (duplicateKeysAre.length === 0) {
          distinctAppConfigKeys = [...fieldToExcludes];
        } else {
          distinctAppConfigKeys = fieldToExcludes.filter((duplicated) => (
            !duplicateKeysAre.includes(duplicated)
          ));
        }
        const updatedField = uniqueFields.filter((field: string) => (
          !distinctAppConfigKeys.includes(field)
        ));
        const afReportPdfExportFields = airFreightReportPdfExportHeaderFileds;
        const checkFieldsForPdfOption = filetype === 'PDF' ? afReportPdfExportFields : updatedField;
        const airFreightReportRequestBody = showForTypePayload ? {
          search: [...(searchRequestBody.search) || []],
          fields: checkFieldsForPdfOption,
          searchType: searchRequestBody.searchType,
          fileFormat: filetype,
          type: 'airFreight'
        } : {
          search: [...(searchRequestBody.search) || []],
          fields: checkFieldsForPdfOption,
          searchType: searchRequestBody.searchType,
          fileFormat: filetype
        };
        let payload = { ...airFreightReportRequestBody };
        if (payload?.search) {
          // to add division: 20 in request data
          payload = {
            ...payload,
            search: setDivisionCode20(payload?.search)
          };
        }
        payload = setValidFields(
          airFreightReportFieldProperties,
          payload,
          [],
          inactiveFeatureFields
        );
        request({
          api: showForAF ? 'airFreightReportExportLargeFile' : 'airFreightReportExport',
          method: 'post',
          data: payload
        }, dispatch, getState)
          .then((response) => {
            callback(response);
            dispatch(toggleSpinner(false));
          })
          .catch((error) => {
            callback(null, error);
            dispatch(toggleSpinner(false));
          });
      }
    );
  }

  function fetchSavedAirFreightReportResults() {
    return createAsyncThunk(
      `${name}/fetchSavedAirFreightReportResults`,
      async ({
        airFreightReportRequestData, callback, inactiveFeatureFields
      }, { getState, dispatch }) => {
        const state = getState();
        let requestBody = { ...airFreightReportRequestData.searchCriteria, offset: '0' };
        // const rowsPerPageOption = state?.searchPanelData?.rowPerPageOption;
        requestBody = setValidFields(
          airFreightReportFieldProperties,
          requestBody,
          [],
          inactiveFeatureFields
        );
        const {
          rowPerPageOption: rowsPerPageOption
        } = state.searchpaneldata || {};
        dispatch(toggleSpinner(true));
        dispatch(updateDialogStates({ executeBookmarkSpinner: true }));
        let payload = { ...requestBody };
        if (payload?.search) {
          payload = {
            ...payload,
            search: setDivisionCode20(payload?.search)
          };
        }
        request({
          api: 'airFreightReportSearch',
          method: 'post',
          data: showForTypePayload ? {
            savedSearchID: airFreightReportRequestData.id,
            ...payload,
            type: 'airFreight'
          } : {
            savedSearchID: airFreightReportRequestData.id,
            ...payload
          },
          cancellable: true
        }, dispatch, getState)
          .then((response) => {
            const fields = mapRequestToInputFields([
              ...airFreightReportRequestData.searchCriteria.search
            ]);
            const airFreightSearchFields = [...new Set(
              airFreightReportRequestData.searchCriteria.search.map((field) => field.fieldName)
            )];
            dispatch(actions.updateAirFreightReportInputValues(
              fields
            ));
            dispatch(actions.updateAirFreightReportRequestBody(requestBody));
            dispatch(actions.updateAirFreightReportFields(airFreightReportFieldList
              .map((field) => field.key)
              .filter((field) => airFreightSearchFields.includes(field))));
            dispatch(actions.updateAirFreightReportResults(response));
            dispatch(actions.updateAirFreightReportSearchId());
            dispatch(actions.updateAirFreightReportPage(0));
            dispatch(actions.updateAirFreightReportRowsPerPage(rowsPerPageOption));
            dispatch(actions.updateAirFreightReportBookmarkId(airFreightReportRequestData.id));
            dispatch(
              actions.updateairFreightReportBookmarkName(airFreightReportRequestData.name)
            );
            dispatch(toggleSpinner(false));
            dispatch(updateDialogStates({ executeBookmarkSpinner: false }));
            if (callback) {
              callback(response);
            }
          })
          .catch((error) => {
            dispatch(toggleSpinner(false));
            dispatch(updateDialogStates({ executeBookmarkSpinner: false }));
            console.log(error);
            if (callback) {
              callback(null, error);
            }
          });
      }
    );
  }

  function fetchAirFreightreportsResultsOffset() {
    return createAsyncThunk(
      `${name}/fetchAirFreightreportsResultsOffset`,
      async (_, { getState, dispatch }) => {
        const state = getState();
        if (state.airFreightReport.airFreightReportRequestBody
          && state.airFreightReport.airFreightReportResultData) {
          let airFreightReportUpdatedRequest = state.airFreightReport
            .airFreightReportRequestBody;
          if (airFreightReportUpdatedRequest?.search) {
            // to add division: 20 in request data
            airFreightReportUpdatedRequest = {
              ...airFreightReportUpdatedRequest,
              search: setDivisionCode20(airFreightReportUpdatedRequest.search)
            };
          }
          airFreightReportUpdatedRequest = {
            ...airFreightReportUpdatedRequest,
            offset: '0'
          };
          dispatch(toggleSpinner(true));
          request({
            api: 'airFreightReportSearch',
            method: 'post',
            data: showForTypePayload ? { ...airFreightReportUpdatedRequest, type: 'airFreight' } : airFreightReportUpdatedRequest
          }, dispatch, getState)
            .then((response) => {
              dispatch(actions.airFreightReportOffset(response));
              dispatch(toggleSpinner(false));
              dispatch(actions.updateAirFreightReportSearchId());
            })
            .catch((error) => {
              console.log('NEXT Error ', error);
              dispatch(toggleSpinner(false));
            });
        }
        return () => false;
      }
    );
  }

  function airFreightReportDataOffset() {
    return createAsyncThunk(
      `${name}/airFreightReportDataOffset`,
      async ({
        FetchAirFreightreportsResultsOffset
      }, { dispatch }) => {
        dispatch(FetchAirFreightreportsResultsOffset());
      }
    );
  }

  function fetchAirFreightReportNextResultSet() {
    return createAsyncThunk(
      `${name}/fetchAirFreightReportNextResultSet`,
      async (_, { getState, dispatch }) => {
        const state = getState();
        dispatch(togglePaginationSpinner({ isFetchingNextData: true }));
        if (state.airFreightReport.airFreightReportRequestBody
          && state.airFreightReport.airFreightReportResultData
          && state.airFreightReport.airFreightReportResultData.pages.next) {
          const currentResultSet = state.airFreightReport
            .airFreightReportResultData;
          let updatedRequest = state.airFreightReport
            .airFreightReportRequestBody;
          const searchResultNextOffset = state.airFreightReport
            .airFreightReportResultData.pages.next;
          // delete updatedRequest.offset;
          const resNext = searchResultNextOffset.indexOf(',');
          // updatedRequest.offset = searchResultNextOffset.slice(7, resNext);
          updatedRequest = {
            ...updatedRequest,
            offset: searchResultNextOffset.slice(7, resNext)
          };
          let payload = { ...updatedRequest };
          if (payload?.search) {
            // to add division: 20 in request data
            payload = {
              ...payload,
              search: setDivisionCode20(payload?.search)
            };
          }
          request({
            api: 'airFreightReportSearch',
            method: 'post',
            data: showForTypePayload ? { ...payload, type: 'airFreight' } : payload
          }, dispatch)
            .then((response) => {
              dispatch(actions.updateAirFreightReportRequestBodyOnSameBookmark(updatedRequest));
              dispatch(togglePaginationSpinner({ isFetchingNextData: false }));
              dispatch(toggleSpinner(false));
              dispatch(actions.updateAirFreightReportNextResult({ response, currentResultSet }));
            })
            .catch((error) => {
              dispatch(togglePaginationSpinner({ isFetchingNextData: false }));
              console.log('NEXT Error ', error);
            });
        }
        return () => false;
      }
    );
  }

  function ChangeReportsDataNextSet() {
    return createAsyncThunk(
      `${name}/ChangeReportsDataNextSet`,
      async ({
        FetchAirFreightReportNextResultSet
      }, { dispatch }) => {
        dispatch(FetchAirFreightReportNextResultSet());
      }
    );
  }

  function fetchReportPrevResultSet() {
    return createAsyncThunk(
      `${name}/fetchReportPrevResultSet`,
      async (_, { getState, dispatch }) => {
        const state = getState();
        if (state.airFreightReport
          && state.airFreightReport.airFreightReportResultData
          && state.airFreightReport.airFreightReportResultData.pages.prev) {
          const currentResultSet = state.airFreightReport
            .airFreightReportResultData;
          let updatedRequest = state.airFreightReport
            .airFreightReportRequestBody;
          const searchResultPrevOffset = state.airFreightReport
            .airFreightReportResultData.pages.prev;
          // delete updatedRequest.offset;
          const res = searchResultPrevOffset.indexOf(',');
          updatedRequest = {
            ...updatedRequest,
            offset: searchResultPrevOffset.slice(7, res)
          };
          let payload = { ...updatedRequest };
          if (payload?.search) {
            // to add division: 20 in request data
            payload = {
              ...payload,
              search: setDivisionCode20(payload?.search)
            };
          }
          dispatch(togglePaginationSpinner({ isFetchingPrevData: true }));
          request({
            api: 'airFreightReportSearch',
            method: 'post',
            data: showForTypePayload ? { ...payload, type: 'airFreight' } : payload
          }, dispatch, getState)
            .then((response) => {
              dispatch(actions.updateAirFreightReportRequestBodyOnSameBookmark(updatedRequest));
              dispatch(togglePaginationSpinner({ isFetchingPrevData: false }));
              dispatch(toggleSpinner(false));
              dispatch(actions.updateAirFreightReportPrevResult({ response, currentResultSet }));
            })
            .catch((error) => {
              dispatch(togglePaginationSpinner({ isFetchingPrevData: false }));
              console.log('PREV Error ', error);
            });
        }

        return () => false;
      }
    );
  }

  function ChangeReportsDataPrevSet() {
    return createAsyncThunk(
      `${name}/ChangeReportsDataPrevSet`,
      async ({
        FetchReportPrevResultSet
      }, { dispatch }) => {
        dispatch(FetchReportPrevResultSet());
      }
    );
  }

  function submitAirFreightCreateRequest() {
    return createAsyncThunk(
      `${name}/submitAirFreightCreateRequest`,
      async ({
        overrideData, callback
      }, { dispatch, getState }) => {
        dispatch(toggleSpinner(true));
        request({
          api: 'airFreightCreateOverrideRequest',
          method: 'post',
          data: showForTypePayload ? {
            airFreightData: overrideData,
            type: 'airFreight'
          } : { airFreightData: overrideData }
        }, dispatch, getState)
          .then((response) => {
            dispatch(toggleSpinner(false));
            callback(getflattenResponse(response.data));
          })
          .catch((error) => {
            dispatch(toggleSpinner(false));
            if (callback) {
              callback(null, error);
            }
          });
      }
    );
  }

  function getGacReasonCode() {
    return createAsyncThunk(
      `${name}/getGacReasonCode`,
      async ({
        callback
      }, { dispatch, getState }) => {
        const payload = {};
        dispatch(toggleSpinner(true));
        request({
          api: 'getGACReasonCode',
          method: 'get'
        }, dispatch, getState).then((response) => {
          dispatch(toggleSpinner(false));
          payload.gacReasonCode = response.data;
          if (callback) {
            callback(response);
          }
        }).catch((error) => {
          dispatch(toggleSpinner(false));
          if (callback) {
            callback(null, error);
          }
        });
      }
    );
  }

  function getCountryData() {
    return createAsyncThunk(
      `${name}/getCountryData`,
      async ({ callback }, { dispatch }) => {
        dispatch(toggleSpinner(true));
        request(
          {
            api: 'METADATA',
            method: 'get'
          },
          dispatch
        )
          .then((response) => {
            const { countryList } = response.data;
            const countryCodeList = countryList
              && countryList.map ? countryList.map((option) => ({
                value: option.countryCode,
                text: `${option.countryCode} | ${option.countryName}`
              })) : [];
            dispatch(actions.setCountryCodeDropdown(countryCodeList));
            if (callback) {
              callback(response.data);
            }
            dispatch(toggleSpinner(false));
          })
          .catch((error) => {
            console.log(error);
            dispatch(toggleSpinner(false));
          });
        return true;
      }
    );
  }

  function getPlantCodeData() {
    return createAsyncThunk(
      `${name}/getPlantCodeData`,
      async ({ callback }, { dispatch }) => {
        dispatch(toggleSpinner(true));
        request(
          {
            api: 'GET_PLANT_CODE',
            method: 'get'
          },
          dispatch
        )
          .then((response) => {
            const { plantCodeInfo } = response.data;
            const plantCodeList = plantCodeInfo
              && plantCodeInfo.map ? plantCodeInfo.map((option) => ({
                id: option.plantCode,
                value: `${option.plantCode} | ${option.plantName}`
              })) : [];
            dispatch(actions.setPlantCodeDropdown(plantCodeList));
            if (callback) {
              callback(response.data);
            }
            dispatch(toggleSpinner(false));
          })
          .catch((error) => {
            console.log(error);
            dispatch(toggleSpinner(false));
          });
        // return true;
      }
    );
  }

  return {
    sendAttachedFile: sendAttachedFile(),
    fetchAirFreightReports: fetchAirFreightReports(),
    deleteOverrideAttachment: deleteOverrideAttachment(),
    getOverrideAttachmentFile: getOverrideAttachmentFile(),
    fetchAirFreightHistorySubTable: fetchAirFreightHistorySubTable(),
    fetchAirFreightSubTable: fetchAirFreightSubTable(),
    exportAirFreightReport: exportAirFreightReport(),
    fetchSavedAirFreightReportResults: fetchSavedAirFreightReportResults(),
    fetchAirFreightreportsResultsOffset: fetchAirFreightreportsResultsOffset(),
    airFreightReportDataOffset: airFreightReportDataOffset(),
    fetchAirFreightReportNextResultSet: fetchAirFreightReportNextResultSet(),
    ChangeReportsDataNextSet: ChangeReportsDataNextSet(),
    fetchReportPrevResultSet: fetchReportPrevResultSet(),
    ChangeReportsDataPrevSet: ChangeReportsDataPrevSet(),
    submitAirFreightCreateRequest: submitAirFreightCreateRequest(),
    getGacReasonCode: getGacReasonCode(),
    getCountryData: getCountryData(),
    getPlantCodeData: getPlantCodeData()
  };
}

export const extraActions = createExtraActions();

export const airFreightReportActions = { ...actions, ...extraActions };
export const airFreightReportReducer = slice.reducer;
